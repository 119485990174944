<template>
    <div class="page-body info-linkmodal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app">
                <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <span class="label-app-list-header">
                        {{ isEditing ? 'Edit Informational Link' : 'Add Informational Link' }}
                    </span>
                </div>
            </div>

            <div class="section-body content-checkbox-list scrollable-list">
                <template v-if="infoList.length > 0">
                    <draggable :list="infoList" @change="handleInformationalLinkReorder">
                        <div v-for="info in infoList" :key="info.id"
                            class="d-flex justify-content-between align-items-center ">
                            <ejs-tooltip v-if="infoList.length > 1" class="tooltipcontainer" content="Reorder"
                                target="#target" cssClass="customtooltip" position="BottomRight">
                                <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                            </ejs-tooltip>
                            <span class="label-app-list-header" :class="'w-70'" style="text-overflow: ellipsis;
                                        text-wrap: nowrap;
                                        overflow: hidden;
                                        text-transform: capitalize">
                                {{ info.title }}
                            </span>
                            <div class="d-flex">
                                <ejs-tooltip class="tooltipcontainer" content="Edit" target="#target"
                                    cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-edit me-2" role="button"
                                        @click="editInfo(info)">
                                        edit_square
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                    cssClass="customtooltip" position="BottomRight">
                                    <span id="target" class="material-symbols-outlined icon-delete" role="button"
                                        @click="deleteInfo(info.id)">
                                        delete
                                    </span>
                                </ejs-tooltip>
                            </div>
                        </div>
                    </draggable>
                </template>
            </div>
            <hr v-if="infoList.length" />
            <ValidationObserver ref="form">
                <form style="box-shadow: none; min-height: auto;" class="p-0" @submit.prevent="submit">
                    <div class="d-flex flex-column align-items-center">
                        <div class="w-80 mx-4">
                            <Input label="Title" name="Title" id="TitleInput" placeholder="Enter title"
                                inputClass="input-add-new-dashboard mt-3" labelClass="class-label"
                                v-model="infoLinks.title" required />
                        </div>

                        <div class="w-80 mx-4 my-4">
                            <Input label="Link" name="Link" id="LinkInput" placeholder="Enter link"
                                inputClass="input-add-new-dashboard mt-3" labelClass="class-label"
                                v-model="infoLinks.link" required />
                        </div>

                        <div class="w-80 mx-4 my-4">
                            <label class="class-label">Image*</label>
                            <div class="d-flex align-items-center gap-2">
                                <button type="button" class="no-bg text-button choose-file-btn" @click="chooseFile">
                                    Choose File
                                </button>
                                <input id="informational_link" type="file" hidden @change="uploadImage($event)"
                                    accept="image/jpeg, .webp, .png" ref="upload" />

                                <div class="img-container-small d-flex justify-content-center align-items-center">
                                    
                                      <img v-if="uploadedImg && !isUploading" :src="uploadedImg" alt="Uploaded Image"
                                        style="width: 50px; height: 50px; object-fit: cover; border-radius: 8px;" />

                                    <div v-if="isUploading" class="img-loader-container">
                                        <b-spinner variant="primary" class="img-loader"></b-spinner>
                                        <span class="img-loader-text">Uploading...</span>
                                    </div>
                                    <!-- Delete Button -->
                                    <span v-if="uploadedImg && !isUploading"
                                        class="material-symbols-outlined img-delete-small" role="button"
                                        @click="resetImage">
                                        delete
                                    </span>
                                </div>
                            </div>
                            <span v-if="ImageErr" style="color: #A82A3D">{{ ImageErr }}</span>
                        </div>



                        <div class="my-4 d-flex justify-content-center">
                            <button type="submit" class="primary btn-save">{{ isEditing ? 'Update' : 'Save' }}</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="cancel">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </NoButtonModal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import { companies, informationalLinks } from '../../util/apiRequests';
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'InformationalLinkModal',
    components: { NoButtonModal, ValidationObserver, draggable },
    props: {
        company_id: {
            type: Number,
            required: true,
        },
        infoList: {
            type: []
        }
    },
    data() {
        return {
            infoLinks: {
                id: null,
                title: '',
                link: '',
                image: null,
            },
            ImageErr: null,
            isEditing: false,
            uploadedImg: '',
            isUploading: false,
        };
    },
    methods: {
        async submit() {
            const form = this.$refs.form;
            const isFormValid = await form.validate();

            // Check if an image is uploaded
            if (!this.infoLinks.image) {
                this.ImageErr = 'Image is required';
                return;
            }
            this.ImageErr = null;

            if (!isFormValid) return;

            try {
                if (this.isEditing) {
                    delete this.infoLinks.image_url;
                    const endpoint = informationalLinks.updateInformationalLink(this.company_id, this.infoLinks.id);
                    await this.$api.put(endpoint, this.infoLinks);
                    this.$toasted.success('Link updated successfully');
                } else {
                    const endpoint = informationalLinks.createInformationalLink(this.company_id);
                    await this.$api.post(endpoint, this.infoLinks);
                    this.$toasted.success('Link added successfully');
                }

                this.resetForm();
                this.$emit('save');

            } catch (error) {
                this.$toasted.error('An error occurred while saving the link. Please try again.');
            }
        },
        async handleInformationalLinkReorder() {
            let priorityIndex = 0;

            const infoList = this.infoList.map((item) => {
                return {
                    id: item.id,
                    position: priorityIndex++,
                };
            });

            const endpoint = informationalLinks.updatePriorityOfInformationalLink(this.company_id);
            const response = await this.$api.put(endpoint, { infoList });

            if (!response.data.success) {
                throw new Error(response.data.error);
            }
        },
        chooseFile() {
            this.$refs.upload.click();
        },
        async uploadImage(event) {
            const file = event.target.files[0];
            if (file.size / 1024 / 1024 > 10) {
                this.ImageErr = 'Size should not be greater than 10MB';
                return;
            }
            this.ImageErr = null;
            

            const reader = new FileReader();
            reader.onload = (e) => {
                this.isUploading = true;
                this.uploadedImg = e.target.result; // Set the preview image
            };
            reader.readAsDataURL(file);


            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', event.target.id);
            const endpoint = companies.uploadAsset(this.company_id);
            try {
                const res = await this.$api.post(endpoint, formData);
                if (res.data.success) {
                    this.infoLinks.image = res.data.s3_link;
                }
            } catch {
                this.$toasted.error('Image upload failed');
            } finally {
                this.isUploading = false; // Stop loader
            }
            console.log(this.isUploading)
        },
        resetImage() {
            this.uploadedImg = '';
            this.infoLinks.image = null;
            this.isUploading = false;
        },
        editInfo(info) {
            this.infoLinks = { ...info };
            this.uploadedImg = info.image_url
            this.isEditing = true;
        },
        cancel() {
            this.resetForm();
            this.$emit('cancel');
        },
        resetForm() {
            this.infoLinks = { id: null, title: '', link: '', image: null };
            this.isEditing = false;
        },
        async deleteInfo(id) {
            const endpoint = informationalLinks.deleteInformationalLink(this.company_id, id);

            try {
                await this.$api.delete(endpoint);
                this.$toasted.success('Link deleted successfully');
                this.$emit('save');
            } catch (error) {
                this.$toasted.error('An error occurred while deleting the link. Please try again.');
            }
        }
    }
};
</script>

<style scoped>
.label-app-list-header {
    font-size: 18px;
    font-weight: bold;
}

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }

    .scrollable-list {
        max-height: 300px;
        overflow-y: auto;
        padding: 0.5rem;
        margin-right: 0.5rem;
    }



.content-checkbox-list {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0;
}
</style>
<style>
.info-linkmodal .modal__content {
    padding: 0;
    border-radius: 10px;

}
</style>